import { useFilterState } from '../FilterContext/useFilterState';
import { Icon } from 'components/Basic/Icon/Icon';
import { Checkbox } from 'components/Forms/Checkbox/Checkbox';
import { FC, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';

type FilterFieldType = 'flags' | 'brands';

type FilterGroupProps = {
    title: string;
    isOpen: boolean;
    filterField: FilterFieldType;
};

const getTestIdentifier = (filterField: FilterFieldType) => 'blocks-product-filter-filtergroup-' + filterField;

export const filterGroupTwClass = '-mb-[1px] border-b border-greyLighter';
export const filterGroupTitleTwClass =
    'block relative py-6 pr-5 m-0 uppercase text-black text-base font-bold cursor-pointer';
export const getFilterGroupContentTwClass = (isOpen: boolean) =>
    twJoin('flex wrap flex-col mb-6', isOpen ? 'block' : 'hidden');
export const getFilterGroupContentItemTwClass = (isDisabled: boolean, isActive: boolean) =>
    twJoin('mb-2', isDisabled && !isActive && 'opacity-30 pointer-events-none');
export const filterGroupColorTwClass = 'flex flex-wrap';
export const filterGroupShowAllButtonTwClass =
    'w-fit p-0 text-small border-0 bg-none outline-0 text-black cursor-pointer underline hover:no-underline hover:bg-none hover:text-primary';
export const getFilterGroupArrowTwClass = (isOpen: boolean) =>
    twJoin(
        'transition absolute right-0 top-1/2 text-xs select-none -translate-y-1/2 text-greyLight',
        isOpen ? 'rotate-180' : 'rotate-0',
    );

export const FilterGroup: FC<FilterGroupProps> = ({ title, isOpen, filterField }) => {
    const [isGroupOpen, setIsGroupOpen] = useState(isOpen);
    const [state, dispatch] = useFilterState();
    const selected = useMemo(() => state.selected[filterField], [filterField, state.selected]);
    const options = useMemo(() => state.options[filterField], [filterField, state.options]);

    return (
        <div className="filterGroupTwClass" data-testid={getTestIdentifier(filterField)}>
            <div
                className={filterGroupTitleTwClass}
                onClick={() => setIsGroupOpen((currentGroupVisibility) => !currentGroupVisibility)}
            >
                {title}
                <Icon className={getFilterGroupArrowTwClass(isGroupOpen)} iconType="icon" icon="Arrow" />
            </div>
            <div className={getFilterGroupContentTwClass(isGroupOpen)}>
                {selected.map((dataItem, index) => {
                    const count = typeof options[index] !== 'undefined' ? options[index].count : 0;

                    return (
                        <div
                            className={getFilterGroupContentItemTwClass(count === 0, dataItem.checked)}
                            key={dataItem.uuid}
                            data-testid={getTestIdentifier(filterField) + '-' + index}
                        >
                            <Checkbox
                                id={`${filterField}.${index}.checked`}
                                name={`${filterField}.${index}.checked`}
                                label={dataItem.name}
                                onChange={() =>
                                    dispatch({
                                        type: filterField === 'flags' ? 'setFlags' : 'setBrands',
                                        payload: {
                                            value: { ...dataItem, checked: !dataItem.checked },
                                            index,
                                        },
                                    })
                                }
                                value={dataItem.checked}
                                count={count}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
