import {
    useCheckedBrands,
    useCheckedFlags,
    useFilterState,
    useIsProductFilterEmpty,
} from '../FilterContext/useFilterState';
import { Parameters } from './Parameters/Parameters';
import { Heading } from 'components/Basic/Heading/Heading';
import { Icon } from 'components/Basic/Icon/Icon';
import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useMemo } from 'react';
import { twMergeCustom } from 'utils/twMerge';

const TEST_IDENTIFIER = 'blocks-product-filter-selectedparameters';

export const parametersNameTwClass = 'mb-1 ml-1 py-2 text-sm';
export const parametersListTwClass = 'flex flex-row flex-wrap';
export const parametersListItemTwClass = 'mb-1 ml-1 rounded-sm bg-greyVeryLight px-2 py-1 text-sm flex items-center';
export const parametersListItemRemoveTwClass = 'ml-2 h-3 w-3 translate-y-0.5 cursor-pointer';

export const SelectedParameters: FC = ({ className }) => {
    const t = useTypedTranslationFunction();
    const formatPrice = useFormatPrice();
    const [state, dispatch] = useFilterState();
    const checkedBrands = useCheckedBrands();
    const checkedFlags = useCheckedFlags();
    const isOnlyInStock = useMemo(() => state.selected.onlyInStock, [state.selected.onlyInStock]);
    const isProductFilterEmpty = useIsProductFilterEmpty();
    const minimalPrice = useMemo(() => state.selected.minimalPrice, [state.selected.minimalPrice]);
    const maximalPrice = useMemo(() => state.selected.maximalPrice, [state.selected.maximalPrice]);
    const isMinimalPriceVisible = useMemo(
        () => state.selected.minimalPrice !== state.options.minimalPrice,
        [state.options.minimalPrice, state.selected.minimalPrice],
    );
    const isMaximalPriceVisible = useMemo(
        () => state.selected.maximalPrice !== state.options.maximalPrice,
        [state.options.maximalPrice, state.selected.maximalPrice],
    );

    if (isProductFilterEmpty) {
        return null;
    }

    return (
        <div
            className={twMergeCustom('relative mb-5 rounded-md bg-white px-4 pb-4 pt-7 vl:z-[0]', className)}
            data-testid={TEST_IDENTIFIER}
        >
            <Heading className="uppercase" type="h4">
                {t('Selected filters')}
            </Heading>
            <div className="-ml-1 mb-4">
                {checkedBrands.length > 0 && (
                    <ul className="flex flex-row flex-wrap">
                        <p className="mb-1 ml-1 py-2 text-sm">{t('Brands')}:</p>
                        {checkedBrands.map((filterFormBrand) => (
                            <li
                                className="mb-1 ml-1 rounded-sm bg-greyVeryLight px-2 py-1 text-sm"
                                key={filterFormBrand.uuid}
                            >
                                {filterFormBrand.name}
                                <Icon
                                    className="ml-2 h-3 w-3 translate-y-0.5 cursor-pointer"
                                    iconType="icon"
                                    icon="RemoveThin"
                                    onClick={() => dispatch({ type: 'uncheckBrand', payload: filterFormBrand.uuid })}
                                />
                            </li>
                        ))}
                    </ul>
                )}

                {checkedFlags.length > 0 && (
                    <ul className={parametersListTwClass}>
                        <p className={parametersNameTwClass}>{t('Flags')}:</p>
                        {checkedFlags.map((filterFormFlag) => (
                            <li className={parametersListItemTwClass} key={filterFormFlag.uuid}>
                                {filterFormFlag.name}
                                <Icon
                                    className={parametersListItemRemoveTwClass}
                                    iconType="icon"
                                    icon="RemoveThin"
                                    onClick={() => dispatch({ type: 'uncheckFlag', payload: filterFormFlag.uuid })}
                                />
                            </li>
                        ))}
                    </ul>
                )}
                <Parameters />
                {isOnlyInStock && (
                    <ul className={parametersListTwClass}>
                        <p className={parametersNameTwClass}>{t('Availability')}:</p>
                        <li className={parametersListItemTwClass}>
                            {t('Only goods in stock')}
                            <Icon
                                className={parametersListItemRemoveTwClass}
                                iconType="icon"
                                icon="RemoveThin"
                                onClick={() => dispatch({ type: 'setOnlyInStock', payload: false })}
                            />
                        </li>
                    </ul>
                )}

                {(isMinimalPriceVisible || isMaximalPriceVisible) && (
                    <ul className={parametersListTwClass}>
                        <p className={parametersNameTwClass}>{t('Price')}:</p>
                        <li className={parametersListItemTwClass}>
                            {isMinimalPriceVisible && (
                                <>
                                    <span>{t('from')}&nbsp;</span>
                                    {formatPrice(minimalPrice)}
                                    {isMaximalPriceVisible ? ' ' : ''}
                                </>
                            )}
                            {isMaximalPriceVisible && (
                                <>
                                    <span>{t('to')}&nbsp;</span>
                                    {formatPrice(maximalPrice)}
                                </>
                            )}
                            <Icon
                                className={parametersListItemRemoveTwClass}
                                iconType="icon"
                                icon="RemoveThin"
                                onClick={() => dispatch({ type: 'resetPrices' })}
                            />
                        </li>
                    </ul>
                )}
            </div>
            <button
                className="flex cursor-pointer items-center text-sm no-underline"
                onClick={() => dispatch({ type: 'resetAllParameters' })}
            >
                <div className="font-bold uppercase">{t('Clear all')}</div>
                <Icon className="ml-2 cursor-pointer text-greyLight" iconType="icon" icon="Remove" />
            </button>
        </div>
    );
};
