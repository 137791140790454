import { usePaginationContext } from './usePaginationContext';
import { getNewPagination } from 'helpers/pagination/getNewPagination';
import { PAGE_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { usePagination } from 'hooks/ui/usePagination';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, Fragment, RefObject, useCallback } from 'react';
import { twJoin } from 'tailwind-merge';
import { twMergeCustom } from 'utils/twMerge';

type PaginationProps = {
    totalCount: number;
    containerWrapRef: RefObject<HTMLDivElement> | null;
};

const paginationButtonTwClass =
    'flex h-11 w-11 items-center justify-center rounded-md border border-greyLight bg-white font-bold no-underline hover:no-underline';

const TEST_IDENTIFIER = 'blocks-pagination';
export const DEFAULT_PAGE_SIZE = 12;

export const Pagination: FC<PaginationProps> = ({ totalCount, containerWrapRef }) => {
    const router = useRouter();
    const isDesktop = useMediaMin('sm');
    const [{ page: currentPage }, dispatch] = usePaginationContext();
    const paginationButtons = usePagination(totalCount, currentPage, !isDesktop, DEFAULT_PAGE_SIZE);

    const asPathWithoutQueryParams = router.asPath.split('?')[0];
    const queryParamsWithoutPage = { ...router.query };
    delete queryParamsWithoutPage.all;
    delete queryParamsWithoutPage[PAGE_QUERY_PARAMETER_NAME];

    const onChangePage = useCallback(
        (page: number) => () => {
            dispatch({
                type: 'setPagination',
                payload: getNewPagination(page),
            });
            if (containerWrapRef !== null && containerWrapRef.current !== null) {
                containerWrapRef.current.scrollIntoView();
            }
        },
        [containerWrapRef, dispatch],
    );

    if (paginationButtons === null || paginationButtons.length === 1) {
        return null;
    }

    return (
        <div
            className="mx-auto my-2 flex w-full justify-center gap-1 sm:w-[340px] vl:relative vl:left-full vl:ml-[-340px]"
            data-testid={TEST_IDENTIFIER}
        >
            {paginationButtons.map((pageNumber, index, array) => (
                <Fragment key={pageNumber}>
                    {isDotKey(array[index - 1] ?? null, pageNumber) && (
                        <div
                            className={twJoin(
                                paginationButtonTwClass,
                                'bg-greenVeryLight text-greyDark hover:cursor-default',
                            )}
                        >
                            &#8230;
                        </div>
                    )}
                    {currentPage === pageNumber ? (
                        <div
                            className={twMergeCustom(
                                paginationButtonTwClass,
                                'bg-greyVeryLight brightness-95 hover:cursor-default',
                            )}
                            data-testid={TEST_IDENTIFIER + '-' + pageNumber}
                        >
                            {pageNumber}
                        </div>
                    ) : (
                        <NextLink
                            href={{
                                pathname: asPathWithoutQueryParams,
                                query: {
                                    ...queryParamsWithoutPage,
                                    ...(pageNumber !== 1 ? { page: pageNumber } : {}),
                                },
                            }}
                            passHref
                            shallow
                            scroll={false}
                        >
                            <a
                                className={paginationButtonTwClass}
                                data-testid={TEST_IDENTIFIER + '-' + pageNumber}
                                onClick={onChangePage(pageNumber)}
                            >
                                {pageNumber}
                            </a>
                        </NextLink>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

const isDotKey = (prevPage: number | null, currentPage: number): boolean => {
    return prevPage !== null && prevPage !== currentPage - 1;
};
