import { useFilterState } from '../FilterContext/useFilterState';
import {
    filterGroupColorTwClass,
    filterGroupShowAllButtonTwClass,
    filterGroupTitleTwClass,
    filterGroupTwClass,
    getFilterGroupArrowTwClass,
    getFilterGroupContentItemTwClass,
    getFilterGroupContentTwClass,
} from '../FilterGroup/FilterGroup';
import { SliderFilter } from './SliderFilter/SliderFilter';
import { Icon } from 'components/Basic/Icon/Icon';
import { Checkbox } from 'components/Forms/Checkbox/Checkbox';
import { CheckboxColor } from 'components/Forms/CheckboxColor/CheckboxColor';
import useTranslation from 'next-translate/useTranslation';
import { FC, useMemo, useState } from 'react';
import { ParametersType } from 'types/productFilter';

type FilterGroupParametersProps = {
    title: string;
    isDefaultCollapsed: boolean;
    parameterParentIndex: number;
    data?: ParametersType;
};

const DEFAULT_NUMBER_OF_SHOWN_PARAMETERS = 5;

const getTestIdentifier = (parameterParentIndex: number) =>
    'blocks-product-filter-filtergroup-parameters-' + parameterParentIndex;

export const FilterGroupParameters: FC<FilterGroupParametersProps> = ({
    title,
    isDefaultCollapsed,
    parameterParentIndex,
    data,
}) => {
    const { t } = useTranslation();
    const [isGroupCollapsed, setIsGroupCollapsed] = useState(isDefaultCollapsed);
    const [areParametersVisible, setAreParametersVisible] = useState(false);
    const [state, dispatch] = useFilterState();
    const selectedParameters = useMemo(
        () => (state.selected.parameters.length > 0 ? state.selected.parameters[parameterParentIndex] : undefined),
        [parameterParentIndex, state.selected.parameters],
    );
    const parameters = useMemo(
        () => state.options.parameters?.[parameterParentIndex],
        [parameterParentIndex, state.options.parameters],
    );

    return (
        <div className={filterGroupTwClass} data-testid={getTestIdentifier(parameterParentIndex)}>
            <div
                className={filterGroupTitleTwClass}
                onClick={() => setIsGroupCollapsed((currentGroupVisibility) => !currentGroupVisibility)}
            >
                {title}
                <Icon className={getFilterGroupArrowTwClass(!isGroupCollapsed)} iconType="icon" icon="Arrow" />
            </div>
            <div className={getFilterGroupContentTwClass(!isGroupCollapsed)}>
                {parameters?.__typename === 'ParameterCheckboxFilterOption' && (
                    <>
                        {parameters.values
                            .slice(0, areParametersVisible ? undefined : DEFAULT_NUMBER_OF_SHOWN_PARAMETERS)
                            .filter((dataItem) => dataItem.text.length > 0)
                            .map((dataItem, index) => {
                                const item = selectedParameters?.values[index] ?? undefined;
                                const id = `parameters.${parameterParentIndex}.values.${index}.checked`;

                                return (
                                    <div
                                        className={getFilterGroupContentItemTwClass(
                                            dataItem.count === 0,
                                            item?.checked ?? false,
                                        )}
                                        key={dataItem.uuid}
                                        data-testid={getTestIdentifier(parameterParentIndex) + '-' + index}
                                    >
                                        <Checkbox
                                            id={id}
                                            name={id}
                                            label={dataItem.text}
                                            onChange={
                                                item
                                                    ? () =>
                                                          dispatch({
                                                              type: 'setParameter',
                                                              payload: {
                                                                  value: {
                                                                      ...item,
                                                                      checked: !item.checked,
                                                                  },
                                                                  parameterIndex: parameterParentIndex,
                                                                  valueIndex: index,
                                                              },
                                                          })
                                                    : () => void null
                                            }
                                            value={item?.checked ?? false}
                                            count={dataItem.count}
                                        />
                                    </div>
                                );
                            })}
                        {parameters.values.length > DEFAULT_NUMBER_OF_SHOWN_PARAMETERS && (
                            <button
                                className={filterGroupShowAllButtonTwClass}
                                onClick={() => setAreParametersVisible((prev) => !prev)}
                            >
                                {areParametersVisible ? t('show less') : t('show more')}
                            </button>
                        )}
                    </>
                )}
                {parameters?.__typename === 'ParameterColorFilterOption' && (
                    <div className={filterGroupColorTwClass}>
                        {parameters.values.map((dataItem, index) => {
                            const item = selectedParameters?.values[index] ?? undefined;
                            const id = `parameters.${parameterParentIndex}.values.${index}.checked`;

                            return (
                                <CheckboxColor
                                    key={dataItem.uuid}
                                    bgColor={dataItem.rgbHex ?? undefined}
                                    testIdentifier={getTestIdentifier(index)}
                                    id={id}
                                    name={id}
                                    onChange={
                                        item
                                            ? () =>
                                                  dispatch({
                                                      type: 'setParameter',
                                                      payload: {
                                                          value: {
                                                              ...item,
                                                              checked: !item.checked,
                                                          },
                                                          parameterIndex: parameterParentIndex,
                                                          valueIndex: index,
                                                      },
                                                  })
                                            : () => void null
                                    }
                                    value={item?.checked ?? false}
                                    label={dataItem.text}
                                />
                            );
                        })}
                    </div>
                )}
                {data?.__typename === 'ParameterSliderFilterOption' && (
                    <SliderFilter
                        parameterParentIndex={parameterParentIndex}
                        min={data.minimalValue}
                        max={data.maximalValue}
                    />
                )}
            </div>
        </div>
    );
};
