import {
    parametersListItemRemoveTwClass,
    parametersListItemTwClass,
    parametersListTwClass,
    parametersNameTwClass,
} from '../SelectedParameters';
import { Icon } from 'components/Basic/Icon/Icon';
import { useCheckedParameters, useFilterState } from 'components/Blocks/Product/Filter/FilterContext/useFilterState';
import { getIndexOfParameter } from 'components/Blocks/Product/Filter/helpers/getIndexOfParameter';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { FC, Fragment, useMemo } from 'react';
import { FilterFormParameterType } from 'types/productFilter';

const TEST_IDENTIFIER = 'blocks-product-filter-selectedparameters-parameters-';

export const Parameters: FC = () => {
    const t = useTypedTranslationFunction();
    const checkedParameters = useCheckedParameters();
    const [state, dispatch] = useFilterState();
    const filterOptions = useMemo(() => state.options, [state.options]);

    const isMinMaxValueVisible = (
        filteredParameter: FilterFormParameterType,
        valueType: 'minimalValue' | 'maximalValue',
    ) => {
        const parameter = filterOptions.parameters
            ? filterOptions.parameters[getIndexOfParameter(filterOptions.parameters, filteredParameter.parameterUuid)]
            : null;

        return (
            filteredParameter[valueType] !== null &&
            parameter?.__typename === 'ParameterSliderFilterOption' &&
            parameter[valueType] !== filteredParameter[valueType]
        );
    };

    return (
        <>
            {checkedParameters.map((filteredParameter) => (
                <Fragment key={filteredParameter.parameterUuid}>
                    {(isMinMaxValueVisible(filteredParameter, 'minimalValue') ||
                        isMinMaxValueVisible(filteredParameter, 'maximalValue')) && (
                        <ul className={parametersListTwClass}>
                            <p className={parametersNameTwClass}>{filteredParameter.parameterName}:</p>
                            <li className={parametersListItemTwClass}>
                                {isMinMaxValueVisible(filteredParameter, 'minimalValue') && (
                                    <>
                                        <span>{t('from')}&nbsp;</span>
                                        {filteredParameter.minimalValue}
                                        {filteredParameter.unit?.name !== undefined
                                            ? `\xa0${filteredParameter.unit.name}`
                                            : ''}
                                        {isMinMaxValueVisible(filteredParameter, 'maximalValue') ? ' ' : ''}
                                    </>
                                )}
                                {isMinMaxValueVisible(filteredParameter, 'maximalValue') && (
                                    <>
                                        <span>{t('to')}&nbsp;</span>
                                        {filteredParameter.maximalValue}
                                        {filteredParameter.unit?.name !== undefined
                                            ? `\xa0${filteredParameter.unit.name}`
                                            : ''}
                                    </>
                                )}
                                <Icon
                                    className={parametersListItemRemoveTwClass}
                                    iconType="icon"
                                    icon="RemoveThin"
                                    onClick={() =>
                                        dispatch({
                                            type: 'uncheckSliderParameter',
                                            payload: filteredParameter.parameterUuid,
                                        })
                                    }
                                />
                            </li>
                        </ul>
                    )}
                    {filteredParameter.values.length > 0 && (
                        <ul className={parametersListTwClass}>
                            <p className={parametersNameTwClass}>{filteredParameter.parameterName}:</p>
                            {filteredParameter.values.map(
                                (value, index) =>
                                    value.checked && (
                                        <li
                                            className={parametersListItemTwClass}
                                            key={value.uuid}
                                            data-testid={TEST_IDENTIFIER + index}
                                        >
                                            {value.text}
                                            <Icon
                                                className={parametersListItemRemoveTwClass}
                                                iconType="icon"
                                                icon="RemoveThin"
                                                onClick={() =>
                                                    dispatch({
                                                        type: 'uncheckParameter',
                                                        payload: {
                                                            uuid: filteredParameter.parameterUuid,
                                                            valueUuid: value.uuid,
                                                        },
                                                    })
                                                }
                                                data-testid={TEST_IDENTIFIER + 'remove-' + index}
                                            />
                                        </li>
                                    ),
                            )}
                        </ul>
                    )}
                </Fragment>
            ))}
        </>
    );
};
