import { usePaginationContext } from '../Pagination/usePaginationContext';
import { useProductFilterOptions } from '../Product/Filter/FilterContext/useFilterState';
import { Icon } from 'components/Basic/Icon/Icon';
import { isElementVisible } from 'components/Helpers/isElementVisible';
import { mobileFirstSizes } from 'components/Theme/mediaQueries';
import { ProductOrderingModeEnumApi } from 'graphql/generated';
import { getFilterUrlQueryForSortingInSeoCategory } from 'helpers/filterOptions/getFilterUrlQueryForSortingInSeoCategory';
import { getQueryWithoutAllParameter } from 'helpers/filterOptions/getQueryWithoutAllParameter';
import { shallowReplaceIfDifferent } from 'helpers/filterOptions/shallowReplaceIfDifferent';
import { FILTER_QUERY_PARAMETER_NAME, SORT_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import { getProductListSort } from 'helpers/sorting/getProductListSort';
import { parseProductListSortFromQuery } from 'helpers/sorting/parseProductListSortFromQuery';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useGetWindowSize } from 'hooks/ui/useGetWindowSize';
import { useResizeWidthEffect } from 'hooks/ui/useResizeWidthEffect';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

type SortingBarProps = {
    totalCount: number;
    sorting: ProductOrderingModeEnumApi | null;
};

const TEST_IDENTIFIER = 'blocks-sortingbar';

const sortingBarItemTwClass = 'relative vl:ml-8';
const getSortingBarOptionsWrapTwClass = (isActive: boolean) =>
    twJoin(
        'absolute left-0 top-0 flex w-full flex-col overflow-hidden rounded-lg border-b border-greyLighter bg-secondary vl:top-1 vl:flex-row vl:items-center vl:justify-between vl:rounded-none vl:bg-transparent',
        isActive && 'z-above',
    );
const getSortingBarItemLinkTwClass = (isActive: boolean) =>
    twJoin(
        'block py-4 px-2 text-center text-xs no-underline uppercase transition hover:no-underline vl:py-2 vl:rounded-none vl:px-0 vl:hover:bg-transparent text-white vl:text-secondary',
        isActive &&
            'text-primary after:absolute after:left-0 after:bottom-0 after:w-full after:h-0.5 after:content-[""] after:bg-primary after:cursor-auto vl:text-primary',
    );

export const SortingBar: FC<SortingBarProps> = ({ sorting, totalCount }) => {
    const router = useRouter();
    const t = useTypedTranslationFunction();
    const [, dispatch] = usePaginationContext();
    const sortingFromQuery = getProductListSort(parseProductListSortFromQuery(router.query[SORT_QUERY_PARAMETER_NAME]));
    const [selectedSort, setSelectedSort] = useState<ProductOrderingModeEnumApi | null>(sorting ?? sortingFromQuery);
    const { width } = useGetWindowSize();
    const [isMobileSortBarVisible, setMobileSortBarVisible] = useState(true);
    const productFilterOptions = useProductFilterOptions();
    useResizeWidthEffect(
        width,
        mobileFirstSizes.vl,
        () => setMobileSortBarVisible(false),
        () => setMobileSortBarVisible(true),
        () => setMobileSortBarVisible(isElementVisible([{ min: 0, max: 1024 }], width)),
    );
    const [toggleSortMenu, setToggleSortMenu] = useState(false);
    const sortValues = [
        { stateValue: ProductOrderingModeEnumApi.PriorityApi, displayValue: t('recommended') },
        { stateValue: ProductOrderingModeEnumApi.PriceAscApi, displayValue: t('price ascending') },
        { stateValue: ProductOrderingModeEnumApi.PriceDescApi, displayValue: t('price descending') },
    ];

    useEffect(() => {
        const handleCloseSortingBar = () => {
            if (isMobileSortBarVisible && toggleSortMenu) {
                setToggleSortMenu(false);
            }
        };

        document.addEventListener('click', handleCloseSortingBar);

        return () => {
            document.removeEventListener('click', handleCloseSortingBar);
        };
    }, [isMobileSortBarVisible, toggleSortMenu]);

    useEffect(() => {
        setSelectedSort(sorting);
    }, [sorting]);

    const updateUrlWithCurrentSort = useCallback(
        (sort: string) => {
            const pathname = router.asPath.split('?')[0];
            const queryParams = getQueryWithoutAllParameter(router);

            queryParams[SORT_QUERY_PARAMETER_NAME] = sort;

            const filterUrlQuery =
                productFilterOptions !== null ? getFilterUrlQueryForSortingInSeoCategory(productFilterOptions) : null;

            if (filterUrlQuery !== null) {
                queryParams[FILTER_QUERY_PARAMETER_NAME] = filterUrlQuery;
            }

            shallowReplaceIfDifferent(router, { pathname, query: queryParams });
        },
        [router, productFilterOptions],
    );

    const onSelectSortMenu = useCallback(
        (currentSort: ProductOrderingModeEnumApi | null, newSort: ProductOrderingModeEnumApi) => () => {
            setToggleSortMenu((prev) => !prev);
            if (isNewSortDifferentThanCurrent(currentSort, newSort)) {
                updateUrlWithCurrentSort(newSort);
                setSelectedSort(newSort);
            }
        },
        [updateUrlWithCurrentSort],
    );

    const onMobileSort = useCallback(
        (currentSort: ProductOrderingModeEnumApi | null, newSort: ProductOrderingModeEnumApi) => () => {
            if (isNewSortDifferentThanCurrent(currentSort, newSort)) {
                setToggleSortMenu((prev) => !prev);
                updateUrlWithCurrentSort(newSort);
                setSelectedSort(newSort);
                dispatch({ type: 'resetPagination' });
            }
        },
        [dispatch, updateUrlWithCurrentSort],
    );

    const onSort = useCallback(
        (currentSort: ProductOrderingModeEnumApi | null, newSort: ProductOrderingModeEnumApi) => () => {
            if (isNewSortDifferentThanCurrent(currentSort, newSort)) {
                updateUrlWithCurrentSort(newSort);
                setSelectedSort(newSort);
                dispatch({ type: 'resetPagination' });
            }
        },
        [dispatch, updateUrlWithCurrentSort],
    );

    return (
        <div className="relative h-12 w-40 vl:inline-block vl:h-8 vl:w-full" data-testid={TEST_IDENTIFIER}>
            {isMobileSortBarVisible ? (
                <div className={getSortingBarOptionsWrapTwClass(toggleSortMenu)}>
                    <div className={sortingBarItemTwClass}>
                        {sortValues
                            .filter((value) => value.stateValue === selectedSort)
                            .map((value) => (
                                <div
                                    className="flex h-[52px] items-center justify-center py-1"
                                    key={value.stateValue}
                                    onClick={onSelectSortMenu(selectedSort, value.stateValue)}
                                    data-testid={TEST_IDENTIFIER + '-selected'}
                                >
                                    <Icon className="h-4 w-5 align-middle text-white" iconType="icon" icon="Sort" />
                                    <div className="pl-2 text-justify font-bold text-white">
                                        <div className="text-base uppercase leading-default">{t('Sort')}</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    {toggleSortMenu &&
                        sortValues
                            .filter((value) => value.stateValue !== selectedSort)
                            .map((value, index) => {
                                return (
                                    <div className={sortingBarItemTwClass} key={value.stateValue}>
                                        <a
                                            className={getSortingBarItemLinkTwClass(selectedSort === value.stateValue)}
                                            onClick={onMobileSort(selectedSort, value.stateValue)}
                                            data-testid={TEST_IDENTIFIER + '-' + index}
                                        >
                                            {value.displayValue}
                                        </a>
                                    </div>
                                );
                            })}
                </div>
            ) : (
                <div className={getSortingBarOptionsWrapTwClass(toggleSortMenu)}>
                    <div className="static -ml-8 flex flex-row overflow-visible rounded-none">
                        {sortValues.map((value, index) => {
                            return (
                                <div
                                    className={sortingBarItemTwClass}
                                    key={value.stateValue}
                                    onClick={onSort(selectedSort, value.stateValue)}
                                    data-testid={TEST_IDENTIFIER + '-' + index}
                                >
                                    <a className={getSortingBarItemLinkTwClass(selectedSort === value.stateValue)}>
                                        <span className="leading-default">{value.displayValue}</span>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                    <div className="text-xs uppercase text-greyLight">
                        {`${totalCount} `}
                        {t('Products count', { count: totalCount })}
                    </div>
                </div>
            )}
        </div>
    );
};

const isNewSortDifferentThanCurrent = (
    currentSort: ProductOrderingModeEnumApi | null,
    newSort: ProductOrderingModeEnumApi,
) => currentSort !== newSort;
