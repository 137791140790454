import { useFilterState } from '../FilterContext/useFilterState';
import {
    filterGroupTitleTwClass,
    filterGroupTwClass,
    getFilterGroupArrowTwClass,
    getFilterGroupContentTwClass,
} from '../FilterGroup/FilterGroup';
import { Icon } from 'components/Basic/Icon/Icon';
import { Checkbox } from 'components/Forms/Checkbox/Checkbox';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { FC, useMemo, useState } from 'react';

type FilterGroupInStockProps = {
    title: string;
    isOpen: boolean;
    inStockCount: number;
};

const TEST_IDENTIFIER = 'blocks-product-filter-filtergroup-instock';

export const FilterGroupInStock: FC<FilterGroupInStockProps> = ({ title, isOpen }) => {
    const t = useTypedTranslationFunction();
    const [isGroupOpen, setIsGroupOpen] = useState(isOpen);
    const [state, dispatch] = useFilterState();
    const isOnlyInStock = useMemo(() => state.selected.onlyInStock, [state.selected.onlyInStock]);
    const inStockCount = useMemo(() => state.options.inStock, [state.options.inStock]);

    return (
        <div className={filterGroupTwClass} data-testid={TEST_IDENTIFIER}>
            <div
                className={filterGroupTitleTwClass}
                onClick={() => setIsGroupOpen((currentGroupVisibility) => !currentGroupVisibility)}
            >
                {title}
                <Icon className={getFilterGroupArrowTwClass(isGroupOpen)} iconType="icon" icon="Arrow" />
            </div>
            <div className={getFilterGroupContentTwClass(isGroupOpen)}>
                <Checkbox
                    name="onlyInStock"
                    id="onlyInStock"
                    onChange={() => dispatch({ type: 'setOnlyInStock', payload: !isOnlyInStock })}
                    label={t('In stock')}
                    count={inStockCount}
                    value={isOnlyInStock}
                />
            </div>
        </div>
    );
};
