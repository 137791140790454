import { DEFAULT_PAGE_SIZE } from 'components/Blocks/Pagination/Pagination';
import { PaginationState } from 'components/Blocks/Pagination/types';
import { encode } from 'js-base64';

export const getNewPagination = (
    page: number,
    pageSize: number = DEFAULT_PAGE_SIZE,
    endCursorProp: string | null = null,
): PaginationState => {
    if (page > 1) {
        const endCursor = endCursorProp ?? encode('arrayconnection:' + (page * pageSize - (pageSize + 1)).toString());
        return { page, endCursor };
    }

    return { page: 1, endCursor: '' };
};
